<template>
  <div>
    <!-- Conditional rendering based on the active tab -->
    <slot :name="currentTab"></slot>
  </div>
</template>

<script>
export default {
  props: {
    currentTab: {
      type: String,
      required: true,
    },
  },
  watch: {
    currentTab(newVal, oldVal) {
      console.log("Tab changed from", oldVal, "to", newVal);
      // This will log to the console every time the tab changes.
    },
  },
};
</script>
