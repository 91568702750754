<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <page-header title="Goods In" backTo="internal">
      <router-link
          :to="{ name: 'goods-in-create' }"
          class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
        >
          <path d="M12 5v14M5 12h14"/>
        </svg>
        <span>New Delivery</span>
      </router-link>
    </page-header>

    <div class="p-6 bg-background flex-1 overflow-y-scroll">
      <div class="w-full bg-white p-5 rounded shadow-md relative">
        <!-- Toolbar with Tab Headers and Search Controls -->
        <div class="flex justify-between items-center mb-4">
          <TabHeaders
              :tabs="tabs"
              :selectedTab="currentTab"
              @tab-selected="onTabSelected"
          />

          <div class="flex flex-1 justify-end">
            <input
                type="text"
                class="ml-10 w-full lg:w-1/2 rounded-l bge-input bge-input-spacing"
                placeholder="Search "
                v-model="searchInput"
                @keydown.enter="triggerSearch"
            />
            <button
                @click="triggerSearch"
                class="rounded-r bg-blue-200 hover:bg-blue-300 border border-blue-400 hover:border-blue-500 p-2 hover:shadow active:shadow-inner transition-colors duration-300 ease-in-out"
            >
              <Icon iconType="search"/>
            </button>
          </div>
        </div>

        <!-- Tab Content -->

        <DataTableComponent :columns="columns" @row-click="onRowClick">
          <template v-slot:timestamp-slot="{ rowData }">
            {{ formatTimestamp(rowData.created_at) }}
          </template>
          <template v-slot:custom-actions-slot="{ rowData }">
            <div class="flex justify-end items-center">
              <!-- Confirm button -->
              <button
                  v-if="!rowData.is_confirmed"
                  type="button"
                  @click.stop="Confirm(rowData)"
                  class="bg-green-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
              >
                <Icon iconType="checkbox"/>
              </button>
              <!-- Edit button -->
              <button
                  type="button"
                  @click.stop="Edit(rowData)"
                  class="ml-2 bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
              >
                <Icon iconType="edit"/>
              </button>
              <!-- Delete button -->
              <button
                  type="button"
                  @click.stop="Delete(rowData)"
                  class="ml-2 bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
              >
                <Icon iconType="delete"/>
              </button>
            </div>
          </template>
        </DataTableComponent>
        <!--        <TabContent :currentTab="currentTab">-->
        <!--          <template v-slot:not_confirmed>-->
        <!--            <DataTableComponent-->
        <!--              :columns="columns"-->
        <!--              @row-select="handleRowSelect"-->
        <!--            />-->
        <!--          </template>-->
        <!--          <template v-slot:confirmed>-->
        <!--            <DataTableComponent-->
        <!--              :columns="columns"-->
        <!--              @row-select="handleRowSelect"-->
        <!--          /></template>-->
        <!--          <template v-slot:search>-->
        <!--            <DataTableComponent-->
        <!--              :columns="columns"-->
        <!--              @row-select="handleRowSelect"-->
        <!--          /></template>-->
        <!--        </TabContent>-->
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Spinner from "@/components/Spinner.vue";
import Panel from "@/components/Panel.vue";
import TabHeaders from "@/components/TabHeaders.vue";
import TabContent from "@/components/TabContent.vue";
import DataTableComponent from "@/views/HR/GoodsIn/Components/DataTableComponent.vue";
import {mapActions, mapState} from "vuex";
import Icon from "@/components/Icon.vue";
import notificationMixin from "@/mixins/notificationMixin";

const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");

export default {
  mixins: [notificationMixin],
  components: {
    PageHeader,
    Spinner,
    Panel,
    IsAuthorized,
    TabHeaders,
    TabContent,
    DataTableComponent,
    Icon,
  },
  data() {
    return {
      tabs: [
        {
          id: "not_confirmed",
          label: "Not Confirmed",
          iconType: "not-confirmed",
        },
        {id: "confirmed", label: "Confirmed", iconType: "confirmed"},
        {id: "search", label: "Search", iconType: "search"},
      ],
      columns: [
        {
          field: "reference_id",
          header: "Ref.ID",
          sortable: true,
          custom: false,
          width: '10%',
        },
        {
          field: "created_at",
          header: "Date / Time",
          sortable: true,
          custom: true,
          slotName: "timestamp-slot",
          width: '13%',
        },
        {
          field: "received_by.name",
          header: "Received By",
          sortable: true,
          custom: false,
          width: '13%',
        },
        {
          field: "courier",
          header: "Courier",
          sortable: true,
          custom: false,
          width: '9%',
        },
        {
          field: "customer.name",
          header: "Cust / Owner",
          sortable: true,
          custom: false,
          width: '15%',
        },
        {
          field: "intended_recipient",
          header: "Recipient",
          sortable: true,
          custom: false,
          width: '15%',
        },
        {
          field: "sender.name",
          header: "Sender",
          sortable: true,
          custom: false,
          width: '15%',
        },
        {
          field: "actions",
          header: "",
          sortable: false,
          custom: true,
          slotName: "custom-actions-slot",
          width: '10%',
        },
      ],
    };
  },
  computed: {
    ...mapState("goodsIn", ["currentTab", "searchText", "selectedCategory"]),

    searchInput: {
      get() {
        return this.$store.state.goodsIn.searchText;
      },
      set(value) {
        this.$store.commit("goodsIn/UPDATE_SEARCH_TEXT", value);
      },
    },
  },
  mounted() {
    this.fetchTableData();
  },
  methods: {
    ...mapActions("goodsIn", [
      "fetchTableData",
      "updateSearchCriteria",
      "updateTab",
    ]),

    onRowClick(event) {
      this.Edit(event.data);
    },
    formatTimestamp(unixTimestamp) {
      return this.$moment.unix(unixTimestamp).format('DD-MM-YYYY HH:mm');
    },
    onTabSelected(newTab) {
      this.setTab(newTab);
    },
    setTab(newTab) {
      this.$store.dispatch("goodsIn/updateTab", newTab);
    },
    triggerSearch() {
      this.setTab("search");
    },
    updateSearch() {
    },
    handleRowSelect(rowObj) {

    },
    async Confirm(rowObj) {
      await this.confirmGoodsInConfirmation(rowObj);
    },
    async confirmGoodsInConfirmation(goodsIn) {
      await this.confirmAction({
        message: `Are you sure you want to confirm the 'Goods In' with reference ID '${goodsIn.reference_id}'?`,
        accept: () => this.executeGoodsInConfirmation(goodsIn),
      });
    },
    async executeGoodsInConfirmation(goodsIn) {
      try {
        const updatedGoodsInData = {...goodsIn, is_confirmed: true};
        await this.GoodsInService.updateGoodsIn(
            goodsIn.goods_in_id,
            updatedGoodsInData,
        );
        await this.$store.dispatch("goodsIn/fetchTableData");
        this.notifySuccess(
            `Goods In with reference ID '${goodsIn.reference_id}' successfully confirmed.`,
        );
      } catch (error) {
        this.notifyError("Failed to confirm Goods In.");
      }
    },
    Edit(rowObj) {
      this.$router.push({
        name: "goods-in-edit",
        params: {
          goods_in_id: rowObj.goods_in_id,
        },
      });
    },
    async Delete(rowObj) {
      const confirmed = await this.confirmDeleteGoodsIn(rowObj);
      if (confirmed) {
        await this.executeGoodsInDeletion(rowObj);
      }
    },

    async confirmDeleteGoodsIn(goodsIn) {
      return await this.confirmAction({
        message: `Are you sure you want to delete the 'Goods In' with reference ID '${goodsIn.reference_id}'?`,
      });
    },
    async executeGoodsInDeletion(goodsIn) {
      try {
        await this.GoodsInService.deleteGoodsIn(goodsIn.goods_in_id);
        await this.$store.dispatch("goodsIn/fetchTableData");
        this.notifySuccess(
            `Goods In with reference ID '${goodsIn.reference_id}' successfully deleted.`,
        );
      } catch (error) {
        this.notifyError("Failed to delete Goods In.");
      }
    },
  },
};
</script>
